<template>
  <edit-item
      :baseUrl="baseUrl"
      :submitBtnTitle="submitBtnTitle"
      :title="title"
  />
</template>

<script>
import EditItem from './../components/EditItem'

export default {
  components: {
    EditItem
  },
  data() {
    return {
      submitBtnTitle: this.$t('messages.itemUpdate'),
      title: this.$t('messages.itemUpdate'),
      baseUrl: '/others',
    }
  },
}
</script>
